import React from "react"
import { Link } from "gatsby"
import slugify from "slugify"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { FadeInTop } from "."

const BlogArticleCard = React.forwardRef(({ data, className }, ref) => {
  const {
    title,
    category,
    slug: { current: slug },
    mainImage,
    publishedAt,
  } = data
  let articleImage = mainImage?.asset?.gatsbyImageData
  return (
    <div
      className={`sm:w-1/2 lg:w-1/3 sm:px-4 mb-20 sm:mb-20 2xl:mb-24 ${className}`}
      ref={ref}
    >
      <Link to={`/blog/${slug}`}>
        <GatsbyImage
          image={articleImage}
          alt="Article image"
          className="h-[240px] mb-8"
          imgClassName="hover:scale-110 transition-transform duration-1000"
          imgStyle={{
            transition: `opacity 0.25s linear, transform 1s`,
          }}
        />
      </Link>
      <div className="mb-2">
        {category.map(
          ({ title: categoryTitle, slug: { current: slug } }, i, arr) => (
            <>
              <Link
                to={`/blog/categories/${slug}`}
                className="inline-block text-[#999] mb-1 hover:underline"
              >
                {categoryTitle}
              </Link>
              {/* divider... */}
              {i < arr.length - 1 && (
                <span className="text-[#999] px-2"> | </span>
              )}
            </>
          )
        )}
      </div>

      <h3 className="font-headings text-2xl mb-4">
        <Link to={`/blog/${slug}`} className="hover:underline">
          {title}
        </Link>
      </h3>
      <p className="text-[#999]">{publishedAt}</p>
    </div>
  )
})

export default BlogArticleCard
