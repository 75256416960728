import React from "react"
import { useRef, useEffect, useMemo } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubscribeSection from "../components/Subscribe"
import { useMediaQuery } from "../helpers/hooks"

import {
  FadeInTopList,
  FadeInTop,
  FadeInRight,
  Reveal,
  gsap,
  ScrollTrigger,
  useScrollImageReveal,
} from "../components/animations"

import { annotate } from "rough-notation"
import BlogArticleCard from "../components/BlogArticleCard"
import slugify from "slugify"

const IndexPage = () => {
  useScrollImageReveal()
  const pageCMSData = useStaticQuery(graphql`
    query HomePageCMSData {
      HomePageCMSModulesData: allSanityPageIndex {
        nodes {
          heroTitle: _rawIntroText
          module1 {
            Heading
            emphasis
            paragraph
          }
          module2 {
            heading
            quote
            paragraph
          }
          module3 {
            heading
            subheading
            paragraph: _rawParagraph
          }
        }
      }

      SEOData: sanityPageIndex {
        SEO {
          title: seoTitle
          description: seoDescription
        }
      }

      Expertise: allSanityExpertise {
        nodes {
          title
        }
      }

      BlogArticleHighlights: allSanityPost(
        limit: 2
        sort: { fields: publishedAt, order: DESC }
        filter: { publishedAt: { ne: null } }
      ) {
        nodes {
          title
          category {
            title
            slug {
              current
            }
          }
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          publishedAt(formatString: "D MMMM YYYY")
        }
      }
    }
  `)

  const HomePageCMSModulesData = pageCMSData.HomePageCMSModulesData.nodes[0]
  const SEOData = pageCMSData.SEOData.SEO

  return (
    <Layout>
      <Seo title={SEOData.title} description={SEOData.description} />
      <HeroSection data={HomePageCMSModulesData.heroTitle} />
      <ExpertiseSection data={pageCMSData.Expertise.nodes} />
      <Module1 data={HomePageCMSModulesData.module1} />
      <Module2
        data={{
          ...HomePageCMSModulesData.module2,
          BlogArticleHighlights: pageCMSData.BlogArticleHighlights,
        }}
      />
      <Module3 data={HomePageCMSModulesData.module3} />
      <ConnectSection />
      <SubscribeSection />
    </Layout>
  )
}

const HeroSection = ({ data }) => {
  let heroBgReveal = useRef()
  let linelight = useRef()

  let heroEmphasisText = useRef()

  const serializerComponents = {
    block: {
      h1: props => (
        <h1 className="pt-9 pb-10 xl:pb-14 lg:pt-24 xl:pt-32 md:max-w-[420px] lg:max-w-[700px] xl:max-w-[768px] font-headings text-[42px] lg:text-6xl xl:text-7xl leading-tight lg:leading-[1.25] xl:leading-[1.25]">
          {props.children}
        </h1>
      ),
    },

    marks: {
      underlineAnimation(props) {
        return <span ref={heroEmphasisText}>{props.children}</span>
      },
    },
  }

  useEffect(() => {
    gsap
      .timeline()
      .to(heroBgReveal.current, {
        xPercent: 100,
        duration: 0.8,
        delay: 0.3,
        onComplete: () => {
          heroBgReveal.current.classList.add("hidden")
        },
      })
      .to(linelight.current, {
        x: "100vw",
        duration: 1.3,
        onComplete: () => {
          // event listener for line lighting effect on hero wave background
          document.addEventListener("mousemove", e => {
            gsap.to(linelight.current, {
              x: e.pageX + "px",
            })
          })
        },
      })
      .to(
        heroEmphasisText.current,
        {
          onComplete: () => {
            annotate(heroEmphasisText.current, { type: "underline" }).show()
          },
        },
        "1"
      )
  }, [])

  return (
    <div className="index-hero pt-16 pb-16 lg:pb-48 overflow-hidden">
      {/* <h1 className="font-headings ">{content.intro_text}</h1> */}
      <div className="index-hero__bg-reveal" ref={heroBgReveal}></div>
      <div className="index-hero__linelight" ref={linelight}></div>
      <div className="index-hero__fade absolute"></div>
      <div className="c-container-full relative z-20">
        <FadeInTop delay={0.8}>
          <div>
            <PortableText value={data} components={serializerComponents} />
          </div>
        </FadeInTop>
        <FadeInRight delay={1.7}>
          <Link
            to="about"
            className="anim-elastic-line transform -translate-x-4 opacity-0 tracking-wider"
          >
            learn about us
          </Link>
        </FadeInRight>
      </div>
    </div>
  )
}

const ExpertiseSection = ({ data: expertise }) => {
  const section = useRef(null)
  const isBigScreen = useMediaQuery("(min-width: 1024px)")

  useEffect(() => {
    let expertiseCards = section.current.querySelectorAll(".js-expertise-card")

    if (isBigScreen !== null) {
      if (isBigScreen === false) {
        expertiseCards.forEach((el, i) => {
          ScrollTrigger.create({
            trigger: el,
            start: "center bottom",

            onEnter: () =>
              gsap.to(el, {
                x: 0,
                opacity: 1,
                duration: 1,
                ease: "power1.out",
                delay: Number(`0.${(i + 1) * 2}`),
              }),
          })
        })
      } else if (isBigScreen) {
        console.log("not small screen")
        ScrollTrigger.batch(expertiseCards, {
          onEnter: el =>
            gsap.to(el, {
              y: 0,
              opacity: 1,
              stagger: 0.4,
              duration: 1.5,
              ease: "power1.out",
            }),
        })
      }
    }
  }, [isBigScreen])

  //add slug property to objects
  expertise = expertise.map(exp => ({
    ...exp,
    slug: slugify(exp.title, { lower: true, strict: true }),
  }))

  return (
    <section className="c-container-full pb-20 lg:pb-32 xl:pb-60" ref={section}>
      <FadeInTop delay={1}>
        <h2 className=" c-heading-2">Our Expertise</h2>
      </FadeInTop>
      <div className="flex flex-wrap mt-10 md:space-x-5">
        <div className="js-img-reveal basis-full md:basis-2/5 xl:basis-1/4 h-[370px] xl:h-[410px]">
          <StaticImage
            src="../assets/images/braudit-people-studio.jpg"
            placeholder="blurred"
            alt="braudit people in studio"
            className="w-full h-full"
            imgClassName="object-fit"
          />
        </div>
        <div className="flex flex-wrap mt-10 md:mt-0 space-y-4 xl:space-y-0 basis-full md:flex-1 xl:space-x-7">
          <Link
            to={`/work#${expertise[0].slug}`}
            className="js-expertise-card opacity-0  translate-y-0 -translate-x-4 lg:translate-y-6 lg:translate-x-0 px-6 lg:pl-8 py-10 lg:pt-14 basis-full xl:flex-1 xl:h-[280px] bg-[#151515]"
          >
            <h3 className="flex text-lg xl:text-2xl leading-tight xl:leading-snug">
              {expertise[0].title}
            </h3>
          </Link>
          <Link
            to={`/work#${expertise[1].slug}`}
            className="js-expertise-card opacity-0  translate-y-0 -translate-x-4 lg:translate-y-6 lg:translate-x-0 px-6 lg:pl-8 py-10 lg:pt-14 basis-full xl:flex-1 bg-transparent border border-[#666]"
          >
            <h3 className="flex text-lg xl:text-2xl leading-tight xl:leading-snug">
              {expertise[1].title}
            </h3>
          </Link>
          <Link
            to={`/work#${expertise[2].slug}`}
            className="js-expertise-card opacity-0  translate-y-0 -translate-x-4 lg:translate-y-6 lg:translate-x-0 px-6 lg:pl-8 py-10 lg:pt-14 basis-full xl:flex-1 xl:h-[280px] bg-[#151515]"
          >
            <h3 className="flex text-lg xl:text-2xl leading-tight xl:leading-snug">
              {expertise[2].title}
            </h3>
          </Link>
        </div>
      </div>
    </section>
  )
}

const Module1 = ({ data }) => {
  return (
    <section className="c-container-full flex flex-wrap lg:flex-nowrap justify-between lg:space-x-8 pb-10">
      <div className="w-100 max-w-[620px] lg:max-w-[700px] lg:w-3/5">
        <FadeInTop>
          <h3 className=" font-headings text-4xl xl:text-[3.25rem] leading-snug md:leading-[1.25] mb-12">
            {data.Heading}
          </h3>
        </FadeInTop>
        <FadeInTop>
          <p className=" max-w-[620px] text-lg text-justify sm:text-left mb-12">
            {data.paragraph}
          </p>
        </FadeInTop>
        <FadeInTop>
          <p className=" font-headings text-2xl lg:text-4xl leading-normal lg:leading-snug mb-12">
            {data.emphasis}
          </p>
        </FadeInTop>
        <FadeInRight>
          <Link
            to="work"
            className="anim-elastic-line transform -translate-x-4 opacity-0 tracking-wider mb-12"
          >
            find out more
          </Link>
        </FadeInRight>
      </div>
      <div className="relative overflow-hidden text-center lg:text-right w-full lg:w-2/5 h-auto lg:h-auto lg:max-h-fit">
        <StaticImage
          src="../assets/images/medal-grayscale.png"
          placeholder="none"
          className="max-w-[620px] mx-auto"
          imgClassName="object-top"
          alt="3D Medal Illustration by Pablo Vargas"
        />
      </div>
    </section>
  )
}

const Module2 = ({ data }) => {
  return (
    <section className="bg-[#0a0a0a] pt-20 pb-12 lg:pt-28 xl:pt-40 lg:pb-28 xl:pb-40">
      <div className="c-container-full lg:flex lg:space-x-14 xl:space-x-20">
        <div className="flex items-center lg:w-2/6 justify-center mb-20 lg:mb-0">
          <div className="text-center">
            <h4 className="relative py-10 max-w-[400px]">
              <div className="absolute h-full w-full top-0 left-0 z-10 c-org-section__quote-icon">
                <StaticImage
                  placeholder="none"
                  src="../assets/images/quote-icon-large.png"
                  alt=""
                  className="h-full w-full"
                  objectFit="contain"
                />
              </div>
              <FadeInTop delay={0.7}>
                <em className=" relative z-10 not-italic text-2xl leading-normal font-headings">
                  {data.quote}
                </em>
              </FadeInTop>
            </h4>
          </div>
        </div>
        <div className="md:w-3/4 lg:w-4/6">
          <FadeInTop>
            <h3 className=" font-headings whitespace-pre-line text-4xl xl:text-5xl sm:max-w-none mb-10 leading-normal xl:leading-snug">
              {data.heading}
            </h3>
          </FadeInTop>
          <FadeInTop>
            <p className=" text-lg leading-relaxed mb-10 text-justify lg:text-left lg:max-w-[710px]">
              {data.paragraph}
            </p>
          </FadeInTop>
          <BlogHighlights data={data.BlogArticleHighlights} />
          <FadeInRight>
            <Link
              to="blog"
              className="anim-elastic-line transform -translate-x-4 opacity-0 tracking-wider mb-12"
            >
              visit our blog
            </Link>
          </FadeInRight>
        </div>
      </div>
    </section>
  )
}

const BlogHighlights = ({ data }) => {
  return (
    <div>
      <div className="sm:flex flex-wrap sm:-mx-4 pt-8 lg:pt-14">
        {data.nodes.map((article, i) => (
          <FadeInTop delay={i * 0.2} threshold={0.2}>
            <BlogArticleCard data={article} className="lg:w-1/2" />
          </FadeInTop>
        ))}
      </div>
    </div>
  )
}

const Module3 = ({ data }) => {
  const blockComponents = {
    block: {
      normal({ children }) {
        return (
          <p className=" max-w-[700px] text-justify text-lg leading-relaxed mb-6">
            {children}
          </p>
        )
      },
    },
  }

  return (
    <section className="bg-white pt-12 lg:pt-24 pb-12 lg:pb-24 text-black">
      <div className="c-container-full">
        <FadeInTop>
          <h3 className=" text-3xl lg:text-5xl font-headings mb-10 lg:mb-20">
            {data.heading}
          </h3>
        </FadeInTop>
        <div className="max-w-[820px] lg:ml-40 xl:ml-80">
          <div className="js-img-reveal mb-12 lg:mb-24 h-[279px] lg:h-[400px]">
            <StaticImage
              src="../assets/images/now-team-laughs.jpg"
              placeholder="none"
              // loading="eager"
              className="w-full h-full"
              alt="braudit team laughs"
              objectFit="cover"
              objectPosition="top"
            />
          </div>
          <FadeInTop>
            <h4 className=" max-w-[700px] font-headings text-3xl lg:text-4xl leading-normal lg:leading-tight mb-9 lg:mb-16">
              {data.subheading}
            </h4>
          </FadeInTop>
          <FadeInTop>
            <div className="pb-6">
              <PortableText
                value={data.paragraph}
                components={blockComponents}
              />
            </div>
          </FadeInTop>
          <FadeInRight>
            <Link
              className="c-pre-fade-in-right anim-elastic-line anim-elastic-line--dark"
              to="/about#leadership"
            >
              Meet our leadership
            </Link>
          </FadeInRight>
        </div>
      </div>
    </section>
  )
}

const ConnectSection = () => {
  const connectRef = useRef(null)

  useEffect(() => {
    let connectImg = connectRef.current.querySelector(".gatsby-image-wrapper")
    gsap
      .timeline({
        scrollTrigger: {
          trigger: connectImg,
          scrub: 0.5,
          start: "top bottom",
          end: "bottom top",
        },
      })
      .to(connectImg, {
        xPercent: 10,
        ease: "power1.out",
      })
  }, [])

  return (
    <div
      ref={connectRef}
      className="h-[190px] xl:h-[300px] overflow-x-hidden index-connect flex justify-center items-center"
    >
      <StaticImage
        src="../assets/images/connect-text.svg"
        className="w-full lg:w-11/12 max-w-[1280px] px-4 xl:px-0 transform -translate-x-[50%]"
        objectFit="contain"
        alt=""
      />
    </div>
  )
}

export default IndexPage
