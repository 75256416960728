import { useState, useEffect } from "react"

const useMediaQuery = mediaQuery => {
  const [state, setState] = useState(null)

  const handleMediaQueryChange = theMediaQuery => {
    if (theMediaQuery.matches) {
      setState(true)
    } else {
      setState(false)
    }
  }

  useEffect(() => {
    const theMediaQuery = window.matchMedia(mediaQuery)
    theMediaQuery.addEventListener("change", handleMediaQueryChange)
    handleMediaQueryChange(theMediaQuery)

    return () => {
      theMediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  return state
}

export default useMediaQuery
